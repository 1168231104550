import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import ConfigDB from "../../config";
import DatePicker from "react-multi-date-picker";
import Select from "react-select";
import { getVehicleList } from "../../services/VehicleService";
import { getDriverList } from "../../services/TripService";
import { actionDailyReports } from "../../services/ReportsService";
import Utils from "../../utils";
import { toast } from "react-toastify";

const showReport = [
  {
    value: 1,
    label: "Single Day",
  },
  { value: 2, label: "Multiple Day" },
];

const showDayOfWeek = [
  {
    value: 1,
    label: "Per Column",
  },
  { value: 2, label: "Per Row" },
];

const DailySummaryReports = ({ setActiveTab }) => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const [dateRange, setDateRange] = useState("");

  const handleChange = (range) => {
    setDateRange(range);
  };
  const [DailyData, setDailyData] = useState({
    show_report: "",
    show_days_of_week: "",
    alarm_to_show: "",
    aggregate_alarms: "",
    show_beginning: "",
    show_end: "",
    number_of_trips: "",
    gps_mileage: "",
    canbus_mileage: "",
    travel_time: "",
    average_speed: "",
    first_driver: "",
    last_driver: "",
    different_driver: "",
    fuel_consumption: "",
  });

  const [modifyModal, setModifyModal] = useState(null);

  const [alarmsDetails, setAlarmsDetails] = useState("");

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const [loading, setLoading] = useState(false);

  const handleSendEmailOpen = () => {
    setModifyModal(true);
  };

  const handleSendEmailClose = () => {
    setModifyModal(false);
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      aggregate_alarms: newValue,
    });
  };

  const handleCheckboxChangeShowBeginning = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      show_beginning: newValue,
    });
  };

  const handleCheckboxChangeShowEnd = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      show_end: newValue,
    });
  };

  const handleCheckboxChangeTrips = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      number_of_trips: newValue,
    });
  };

  const handleCheckboxChangeGPS = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      gps_mileage: newValue,
    });
  };
  const handleCheckboxChangeCanBus = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      canbus_mileage: newValue,
    });
  };

  const handleCheckboxChangeTrivelTime = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      travel_time: newValue,
    });
  };

  const handleCheckboxChangeAverageSpeed = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      average_speed: newValue,
    });
  };

  const handleCheckboxChangeFirstDriver = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      first_driver: newValue,
    });
  };

  const handleCheckboxChangeLastDriver = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      last_driver: newValue,
    });
  };

  const handleCheckboxChangeDifferentDriver = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      different_driver: newValue,
    });
  };

  const handleCheckboxChangeFuelConsumption = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      fuel_consumption: newValue,
    });
  };

  const date = new Date(dateRange);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const handleDailyForm = (e) => {
    e.preventDefault();

    if (!dateRange) {
      toast.error("Please select date.");
      return;
    }

    if (!DailyData.show_report) {
      toast.error("Please select report type.");
      return;
    }

    let data = {
      show_report: DailyData?.show_report,
      reportDate: formattedDate,
    };

    modifyDailyReports(actionDailyReports, data);
  };
  const modifyDailyReports = (actionFunction, data) => {
    setLoading(true);
    actionFunction(data)
      .then((response) => {
        setLoading(false);
        // toast.success(response.data.message);

        const dataToSend = {
          show_report: DailyData?.show_report,
          show_days_of_week: DailyData?.show_days_of_week,
          alarm_to_show: DailyData?.alarm_to_show,
          aggregate_alarms: DailyData?.aggregate_alarms,
          show_beginning: DailyData?.show_beginning,
          show_end: DailyData?.show_end,
          number_of_trips: DailyData?.number_of_trips,
          gps_mileage: DailyData?.gps_mileage,
          canbus_mileage: DailyData?.canbus_mileage,
          travel_time: DailyData?.travel_time,
          average_speed: DailyData?.average_speed,
          first_driver: DailyData?.first_driver,
          last_driver: DailyData?.last_driver,
          different_driver: DailyData?.different_driver,
          fuel_consumption: DailyData?.fuel_consumption,
          reportDate: formattedDate,
        };

        localStorage.setItem(
          "DailySummaryReportData",
          JSON.stringify(dataToSend)
        );
        // window.open("/daily-summary", "_blank");

        if (
          !response?.data ||
          response?.data?.data === null ||
          response?.data.data?.length <= 0
        ) {
          toast.error("No data found.");
          return;
        }
        window.open("/daily-summary-reports", "_blank");

        setAlarmsDetails(response.data.data);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleDailyForm}>
              <h6>Daily Summary</h6>
              <h6>
                This report presents the daily summary of the activity of the
                vehicles. Select the indicators you wish to see represented in
                the report.
              </h6>
              <div className="d-flex" style={{ justifyContent: "start" }}>
                <div className="me-1">
                  <Button
                    color="success btn-info"
                    type="submit"
                    className="btn-square"
                    disabled={loading}
                  >
                    {loading ? "loading...." : `Generate`}
                  </Button>
                </div>
                {/* <div>
                  <Button
                    color="success btn-info"
                    type={`button`}
                    onClick={handleSendEmailOpen}
                    className={`btn-square`}
                  >{`Send Report By EMail`}</Button>
                </div> */}
              </div>
            </Form>
          </div>
          <div className="card card-primary general-ac">
            <Form method={`post`}>
              <h6>Configuration</h6>

              <div className="row">
                <div className="col-md-6">
                  <FormGroup>
                    <Label className="col-form-label">{"Period"}</Label>
                    <div className="input-group mb-3 datePicker">
                      <DatePicker
                          selected={dateRange}
                          onChange={handleChange}
                          dateFormat="yyyy-MM-dd"
                          calendarPosition="bottom-center"
                          placeholder="Select Date"

                      />
                      <span
                          className="input-group-text calendarIcon"
                      >
                        <i className="bi bi-calendar-event"></i>
                      </span>
                    </div>


                  </FormGroup>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Show Report"}</Label>
                  <div className="input-div">
                    <Select
                      options={showReport}
                      value={showReport?.find(
                        (option) => option.value === DailyData.show_report
                      )}
                      onChange={(selectedOption) =>
                        setDailyData((prevData) => ({
                          ...prevData,
                          show_report: selectedOption.value,
                        }))
                      }
                      placeholder={"Select show report"}
                    />
                  </div>
                </div>

                {DailyData?.show_report === 2 && (
                  <div className="row">
                    <div className="col-md-6">
                      <Label className="col-form-label">
                        {"Show days of the week"}
                      </Label>
                      <div className="input-div">
                        <Select
                          options={showDayOfWeek}
                          value={showDayOfWeek?.find(
                            (option) =>
                              option.value === DailyData.show_days_of_week
                          )}
                          onChange={(selectedOption) =>
                            setDailyData((prevData) => ({
                              ...prevData,
                              show_days_of_week: selectedOption.value,
                            }))
                          }
                          placeholder={"Select days of the week"}
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-6">
                      <Label className="col-form-label">
                        {"Alarms to show"}
                      </Label>
                      <div className="input-div">
                        <Select
                          options={showDayOfWeek}
                          value={showDayOfWeek?.find(
                            (option) =>
                              option.value === DailyData.show_days_of_week
                          )}
                          onChange={(selectedOption) =>
                            setDailyData((prevData) => ({
                              ...prevData,
                              show_days_of_week: selectedOption.value,
                            }))
                          }
                          placeholder={"Alarms to show"}
                        />
                      </div>
                    </div> */}

                    <div className="col-md-6">
                      <Label className="col-form-label">Aggregate Alarms</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="tachographTypeCheckbox"
                            checked={DailyData.aggregate_alarms}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="tachographTypeCheckbox"
                          >
                            Aggregate Alarms
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">Show beginning</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="tachographTypeCheckbox"
                            checked={DailyData.show_beginning}
                            onChange={handleCheckboxChangeShowBeginning}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="tachographTypeCheckbox"
                          >
                            Show beginning
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">Show End</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="show_end"
                            checked={DailyData.show_end}
                            onChange={handleCheckboxChangeShowEnd}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="show_end"
                          >
                            Show End
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">
                        Show number of trips
                      </Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="number_of_trips"
                            checked={DailyData.number_of_trips}
                            onChange={handleCheckboxChangeTrips}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="number_of_trips"
                          >
                            Show number of trips
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">Show GPS mileage</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="tachographTypeCheckbox"
                            checked={DailyData.show_beginning}
                            onChange={handleCheckboxChangeGPS}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="tachographTypeCheckbox"
                          >
                            Show GPS mileage
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">
                        Show CANBus mileage when available
                      </Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="canbus_mileage"
                            checked={DailyData.canbus_mileage}
                            onChange={handleCheckboxChangeCanBus}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="canbus_mileage"
                          >
                            Show CANBus mileage when available
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">Travel time</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="travel_time"
                            checked={DailyData.travel_time}
                            onChange={handleCheckboxChangeTrivelTime}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="travel_time"
                          >
                            Travel time
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">Average speed</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="average_speed"
                            checked={DailyData.average_speed}
                            onChange={handleCheckboxChangeAverageSpeed}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="average_speed"
                          >
                            Average speed
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">First driver</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="first_driver"
                            checked={DailyData.first_driver}
                            onChange={handleCheckboxChangeFirstDriver}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="first_driver"
                          >
                            First driver
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">Last driver</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="last_driver"
                            checked={DailyData.last_driver}
                            onChange={handleCheckboxChangeLastDriver}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="last_driver"
                          >
                            Last driver
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">
                        Different drivers
                      </Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="different_driver"
                            checked={DailyData.different_driver}
                            onChange={handleCheckboxChangeDifferentDriver}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="different_driver"
                          >
                            Different drivers
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">Fuel consumption</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="fuel_consumption"
                            checked={DailyData.fuel_consumption}
                            onChange={handleCheckboxChangeFuelConsumption}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="fuel_consumption"
                          >
                            Fuel consumption
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Modal isOpen={modifyModal}>
        <ModalHeader>Send Report By Email </ModalHeader>
        <Form method={`post`}>
          <ModalBody>
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <Label className="col-form-label">{"Subject"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id=""
                      placeholder="Subject"
                      name={`subject`}
                      onChange={(e) => {
                        setDailyData({
                          ...DailyData,
                          subject: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={DailyData.subject}
                      // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Email"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      name={`email`}
                      onChange={(e) => {
                        setDailyData({
                          ...DailyData,
                          email: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={DailyData.email}
                      // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              outline
              onClick={handleSendEmailClose}
              className={`btn-square`}
            >{`Close`}</Button>
            <Button
              color="success btn-info"
              type={`submit`}
              className={`btn-square`}
            >{`Submit`}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default DailySummaryReports;
