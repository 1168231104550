import React, { useEffect, useState } from "react";
import { getVehicleAllDetail } from "../../services/VehicleService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate, useParams } from "react-router-dom";

import GeneralCustomer from "./GeneralCustomer";
import AddresCustomer from "./AddresCustomer";
import ContactCustomer from "./ContactCustomer";
import { getcustomerDetails } from "../../services/CustomerService";
import OrderList from "./OrderList";
import InvoiceList from "./InvoiceList";

const AddCustomer = ({ setAddCustomerStatus, CustomerStatusData }) => {
  const [activeTab, setActiveTab] = useState("general");
  const [customerDetails, setCustomerDetails] = useState({});
  const [customerUUId, setCustomerUUId] = useState("");

  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(
    function () {
      if (customerUUId || CustomerStatusData) {
        let data = {
          uuid: customerUUId ? customerUUId : CustomerStatusData?.uuid,
        };
        getcustomerDetails(data)
          .then((response) => {
            setCustomerDetails(response.data.data);
          })
          .catch((err) => {
            toast.error(Utils.getErrorMessage(err));
          });
      }
    },
    [customerUUId, CustomerStatusData]
  );

  return (
    <div>
      <div className="inner-header py-3 d-none">
        <div className="left-block">
          <h5>Customer</h5>
        </div>
      </div>
      <div className="inner-header-back">
        <div className="left-col">
          <button
            type="buttton"
            className="back-btn"
            onClick={() => setAddCustomerStatus(false)}
          >
            <i class="bi bi-chevron-left"></i> Back
          </button>
        </div>
        <div className="tight-block"></div>
      </div>

      <div className="leaflet-control-management managementui-tab leaflet-control-var">
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "general" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("general");
                }}
              >
                <span data-href="#tab-1">General</span>
              </li>
            </ul>

            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "address" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("address");
                }}
              >
                <span data-href="#tab-1">Address</span>
              </li>
            </ul>

            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "contact" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("contact");
                }}
              >
                <span data-href="#tab-1">Contact</span>
              </li>
            </ul>

            {CustomerStatusData?.uuid && (
              <>
                {/* <ul className="tabs-management">
                  <li
                    className={`nav-link ${
                      activeTab === "order" ? "active" : ""
                    }`}
                    role="tab"
                    onClick={(e) => {
                      e.preventDefault();
                      handleTabClick("order");
                    }}
                  >
                    <span data-href="#tab-1">Order</span>
                  </li>
                </ul> */}

                <ul className="tabs-management">
                  <li
                    className={`nav-link ${
                      activeTab === "invoice" ? "active" : ""
                    }`}
                    role="tab"
                    onClick={(e) => {
                      e.preventDefault();
                      handleTabClick("invoice");
                    }}
                  >
                    <span data-href="#tab-1">Invoice</span>
                  </li>
                </ul>
              </>
            )}
          </nav>

          <div className="dashboard-block tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "general" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <GeneralCustomer
                setActiveTab={setActiveTab}
                customerDetails={customerDetails}
                setCustomerUUId={setCustomerUUId}
                customerUUId={customerUUId}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "address" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <AddresCustomer
                setActiveTab={setActiveTab}
                customerDetails={customerDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "contact" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <ContactCustomer
                setActiveTab={setActiveTab}
                customerDetails={customerDetails}
                setAddCustomerStatus={setAddCustomerStatus}
              />
            </div>

            {CustomerStatusData?.uuid && (
              <>
                {/* <div
                  className={`tab-pane fade ${
                    activeTab === "order" ? "show active" : ""
                  }`}
                  id="tab-1"
                  style={{ padding: "20px" }}
                >
                  <OrderList
                    setActiveTab={setActiveTab}
                    customerDetails={customerDetails}
                    setAddCustomerStatus={setAddCustomerStatus}
                  />
                </div> */}

                <div
                  className={`tab-pane fade ${
                    activeTab === "invoice" ? "show active" : ""
                  }`}
                  id="tab-1"
                  style={{ padding: "20px" }}
                >
                  <InvoiceList
                    setActiveTab={setActiveTab}
                    customerDetails={customerDetails}
                    setAddCustomerStatus={setAddCustomerStatus}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCustomer;
