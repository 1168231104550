import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";

const WeekTachnoDetails = ({ TachnoDriverDetailsList }) => {
  const svgContainerRef = useRef(null);
  const [hoveredId, setHoveredId] = useState(null);
  const [clickedId, setClickedId] = useState(null);

  const dateString = clickedId?.slice(-8);
  const formattedDate = moment(dateString, "YYYYMMDD").format("YYYY-MM-DD");

  const handleMouseEnter = (e) => {
    const rectId = e.target.id;
    setHoveredId(rectId);
  };

  const handleMouseLeave = () => {
    setHoveredId(null);
  };

  const handleClick = (e) => {
    const rectId = e.target.id;
    setClickedId(rectId);
  };

  useEffect(() => {
    const svgContainer = svgContainerRef.current;
    console.log("svgContainer", svgContainer);

    if (svgContainer) {
      const rectElements = svgContainer.querySelectorAll("rect");

      rectElements.forEach((rect) => {
        // rect.addEventListener("mouseenter", handleMouseEnter);
        // rect.addEventListener("mouseleave", handleMouseLeave);
        rect.addEventListener("click", handleClick);
      });

      return () => {
        rectElements.forEach((rect) => {
          //   rect.removeEventListener("mouseenter", handleMouseEnter);
          //   rect.removeEventListener("mouseleave", handleMouseLeave);
          rect.removeEventListener("click", handleClick);
        });
      };
    }
  }, []);

  return (
    <div className="dashboard-block tab-content">
      <div className="card card-primary general-ac tachnoCard">
        <h4>
          {TachnoDriverDetailsList?.driver?.name}{" "}
          {TachnoDriverDetailsList?.period?.startDate} to{" "}
          {TachnoDriverDetailsList?.period?.endDate}
        </h4>

        <p>
          {TachnoDriverDetailsList?.activityAnalysis?.weeks?.map((item) => {
            console.log("item", item);
            const fixedSvgString = item?.graph?.replace(/\\"/g, '"');

            return (
              <>
                {item.graph && (
                  <div>
                    <h4>
                      Week From {item.startDate} {item.endDate}
                    </h4>
                    <div
                      className="tab-pane fade show active"
                      id="home-tab-pane"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      tabIndex={0}
                    >
                      <div className="tabContent">
                        <div className="driving">
                          <span />
                          <p>Driving({item.metrics.totalDriving})</p>
                        </div>
                        <div className="driving">
                          <span className="colorGreen" />
                          <p>Other Works ({item.metrics.totalWork})</p>
                        </div>
                        <div className="driving">
                          <span className="colorYellow" />
                          <p>Available ({item.metrics.totalAvailable})</p>
                        </div>
                        <div className="driving">
                          <span className="colorGrey" />
                          <p>Break ({item.metrics.totalBreak})</p>
                        </div>
                        <div className="driving">
                          <span className="colorWhite" />
                          <p>Unknown ({item.metrics.totalUnknown})</p>
                        </div>
                        <div className="driving">
                          <span className="colorBlue" />
                          <p>Amplitude ({item.metrics.totalAmplitude})</p>
                        </div>
                      </div>
                    </div>
                    {/* {hoveredId && formattedDate &&(
                      
                    )} */}

                    <div
                      className="myDIV svgSize"
                      dangerouslySetInnerHTML={{ __html: fixedSvgString }}
                      ref={svgContainerRef}
                    />
                    {clickedId && (
                      <div className="col-lg-2 m-auto hide">
                        <div className="hover-info">
                          {item?.days?.map((dayItem, index) => {
                            return (
                              <>
                                {dayItem?.date === formattedDate && (
                                  <div
                                    key={index}
                                    className="d-flex justify-content-around"
                                  >
                                    <table className="tachnoT">
                                      <thead>
                                        <tr>
                                          <th>Activity</th>
                                          <th>Date</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Other Works</td>
                                          <td>{dayItem.metrics.totalWork}</td>
                                        </tr>

                                        <tr>
                                          <td>Available</td>
                                          <td>
                                            {dayItem.metrics.totalAvailable}
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>Break</td>
                                          <td>{dayItem.metrics.totalBreak}</td>
                                        </tr>

                                        <tr>
                                          <td>Unknown</td>
                                          <td>
                                            {dayItem.metrics.totalUnknown}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Amplitude</td>
                                          <td>
                                            {dayItem.metrics.totalAmplitude}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <div className="">
                      <p>Infringements detected</p>
                      {item?.days?.map((items) => {
                        return (
                          <>
                            {items.infringements?.map((rows) => {
                              return (
                                <>
                                  <p>
                                    {rows.severityLevel === "very_serious" ? (
                                      <>
                                        <div
                                          class="alert alert-danger"
                                          role="alert"
                                        >
                                          <div class="tachnoAlert">
                                            <div className="tachnoTitle">
                                              Incorrect switching
                                            </div>
                                            <div className="tachnodates">
                                              {moment(rows.dateTime).format(
                                                "DD/MM/YYYY HH:mm"
                                              )}{" "}
                                            </div>
                                            <div className="tachnodates">
                                              Fine From {rows.fineMin}€ to{" "}
                                              {rows.fineMax}
                                            </div>
                                            <div className="tachnoBtn">
                                              {rows.severityLevel}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          class="alert alert-warning"
                                          role="alert"
                                        >
                                          <div class="tachnoAlert">
                                            <div className="tachnoTitle">
                                              Incorrect switching
                                            </div>
                                            <div className="tachnodates">
                                              {moment(rows.dateTime).format(
                                                "DD/MM/YYYY HH:mm"
                                              )}{" "}
                                            </div>
                                            <div className="tachnodates">
                                              Non-compliance with rest intervals
                                              during the daily work period
                                            </div>
                                            <div className="tachnodates">
                                              {rows.fineMin}€ to {rows.fineMax}
                                            </div>
                                            <div className="tachnoBtn yellowBg">
                                              {rows.severityLevel}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </p>
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </p>
      </div>
    </div>
  );
};

export default WeekTachnoDetails;
