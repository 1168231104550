import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import Speedometer, {
  Background,
  Arc,
  Needle,
  Progress,
  Marks,
  Indicator,
} from "react-speedometer";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import ConfigDB from "../config";
import {
  getDeviceHelathList,
  getDeviceHelathKeyhList,
  getVehicleList,
} from "../services/VehicleService";
import Select from "react-select";
import Utils from "../utils";
import { toast } from "react-toastify";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { getDeviceDelay } from "../services/TripService";
import Start from "../assets/images/start.svg";
import Stop from "../assets/images/stop.svg";
import moment from "moment";

const containerStyle = {
  width: "100%",
  height: "300px",
};

const libraries = ["places", "drawing"];

const center = {
  lat: 38.78939581924669,
  lng: -9.386177246912233,
  placeName: "Tanzania",
};

const HealthDashboard = () => {
  const navigate = useNavigate();
  const tableLengthList = ConfigDB.data.dataTableLength;
  const [helathData, setHelathData] = useState({});

  const [params] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });
  const [helathGraphData, setHelathGraphData] = useState([]);
  const [clickedServerTime, setClickedServerTime] = useState("");
  const [markerPosition, setMarkerPosition] = useState(center);
  const [vehicleList, setVehicleList] = useState([]);
  const [vehicle, setVehicle] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenProtocol, setIsOpenProtocol] = useState(false);
  const [isOpenSpeed, setIsOpenSpeed] = useState(false);
  const [isOpenIgnition, setIsOpenIgnition] = useState(false);
  const [isOpensignal, setIsOpensignal] = useState(false);
  const [isOpenmovement, setIsOpenmovement] = useState(false);

  const [isOpenhdop, setIsOpenhdop] = useState(false);
  const [isOpenlatitude, setIsOpenlatitude] = useState(false);
  const [isOpenaltitude, setIsOpenaltitude] = useState(false);
  const [isOpenlongitude, setIsOpenlongitude] = useState(false);
  const [isOpetimestamp, setIsOpetimestamp] = useState(false);

  const [devicedelayData, setDeviceDelayData] = useState([]);
  const [directionsForDevice, setDirectionsForDevice] = useState([]);
  let formattedServerDate;
  if (vehicle.vehicleId) {
    formattedServerDate = moment(helathData.server_time).format(
      "YYYY-MM-DD HH:mm:ss"
    );
  }

  const handleClick = (componentName) => {
    setClickedServerTime(componentName);
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const handleClickProtocol = (componentName) => {
    setClickedServerTime(componentName);
    setIsOpenProtocol(true);
  };

  const onCloseProtocol = () => {
    setIsOpenProtocol(false);
  };

  const handleClickSpeed = (componentName) => {
    setClickedServerTime(componentName);
    setIsOpenSpeed(true);
  };

  const onCloseSpeed = () => {
    setIsOpenSpeed(false);
  };

  const handleClickIgnition = (componentName) => {
    setClickedServerTime(componentName);
    setIsOpenIgnition(true);
  };

  const onCloseIgnition = () => {
    setIsOpenIgnition(false);
  };

  const handleClickSignal = (componentName) => {
    setClickedServerTime(componentName);
    setIsOpensignal(true);
  };

  const onCloseSignal = () => {
    setIsOpensignal(false);
  };

  const handleClickaltitude = (componentName) => {
    setClickedServerTime(componentName);
    setIsOpenaltitude(true);
  };

  const onClosealtitude = () => {
    setIsOpenaltitude(false);
  };

  const handleClickhdop = (componentName) => {
    setClickedServerTime(componentName);
    setIsOpenhdop(true);
  };

  const onClosehdop = () => {
    setIsOpenhdop(false);
  };
  const handleClicklatitude = (componentName) => {
    setClickedServerTime(componentName);
    setIsOpenlatitude(true);
  };

  const onCloselatitude = () => {
    setIsOpenlatitude(false);
  };

  const handleClickmovement = (componentName) => {
    setClickedServerTime(componentName);
    setIsOpenlatitude(true);
  };

  const onClosemovement = () => {
    setIsOpenlatitude(false);
  };
  const handleClicklongitude = (componentName) => {
    setClickedServerTime(componentName);
    setIsOpenlongitude(true);
  };

  const onCloselongitude = () => {
    setIsOpenlongitude(false);
  };
  // const handleClicktimestamp = (componentName) => {
  //   setClickedServerTime(componentName);
  //   setIsOpetimestamp(true);
  // };

  // const onClosetimestamp = () => {
  //   setIsOpetimestamp(false);
  // };

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: ConfigDB.data.GOOGLE_MAP_KEY,
    libraries,
  });

  const onMapClick = useCallback(async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
    );
    const data = await response.json();
    const placeName = data.results[0]?.formatted_address || "";
    setMarkerPosition({ lat, lng, placeName });
  }, []);

  useEffect(
    function () {
      let data = params;
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(() => {
    if (vehicle.vehicleId) {
      let data = {
        id: vehicle.vehicleId,
      };
      getDeviceHelathList(data)
        .then((response) => {
          setHelathData(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [vehicle.vehicleId]);

  useEffect(() => {
    if (helathData.device_imei && clickedServerTime) {
      let data = {
        device_imei: helathData.device_imei,
        attributes: [clickedServerTime],
      };
      getDeviceHelathKeyhList(data)
        .then((response) => {
          setHelathGraphData(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [helathData.device_imei, clickedServerTime]);

  useEffect(
    function () {
      if (helathData.device_imei) {
        let data = {
          device_imei: helathData.device_imei,
        };
        getDeviceDelay(data)
          .then((response) => {
            setDeviceDelayData(response.data.data);
          })
          .catch((err) => {
            toast.error(Utils.getErrorMessage(err));
          });
      }
    },
    [helathData, helathData.device_imei]
  );

  const directionsDelayDevice = useCallback((result, status) => {
    if (status === "OK") {
      setDirectionsForDevice(result);
    } else {
      console.error("Error fetching directions:", status);
    }
  }, []);

  const directionsOptionsDelayDevice = useMemo(() => {
    if (
      helathData?.device_imei &&
      devicedelayData[0]?.latitude &&
      devicedelayData[0]?.longitude
    ) {
      const origin = {
        lat: parseFloat(helathData?.latitude),
        lng: parseFloat(helathData?.longitude),
      };

      const destination = {
        lat: parseFloat(devicedelayData[0]?.latitude),
        lng: parseFloat(devicedelayData[0]?.longitude),
      };

      return {
        origin: origin,
        destination: destination,
        travelMode: "DRIVING",
      };
    }

    return null;
  }, [helathData.latitude, helathData.longitude, devicedelayData]);

  useEffect(() => {
    if (
      helathData.device_imei &&
      directionsOptionsDelayDevice?.origin &&
      directionsOptionsDelayDevice?.destination
    ) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: directionsOptionsDelayDevice.origin,
          destination: directionsOptionsDelayDevice.destination,
          travelMode: directionsOptionsDelayDevice.travelMode,
        },
        directionsDelayDevice
      );
    }
  }, [
    helathData.device_imei,
    directionsOptionsDelayDevice,
    directionsDelayDevice,
  ]);

  const chartData = {
    labels: helathGraphData?.map((entry) => entry.server_time),
    datasets: [
      {
        label: "Server Time",
        data: helathGraphData?.map(() => Math.random() * 100),
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.1)",
        fill: true,
      },
    ],
  };

  const chartDataBattery = {
    labels: helathGraphData?.map((entry) => entry.battery_voltage),
    datasets: [
      {
        label: "Battery Voltage",
        data: helathGraphData?.map(() => Math.random() * 100),
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.1)",
        fill: true,
      },
    ],
  };

  const chartDataSpeed = {
    labels: helathGraphData?.map((entry) => entry.speed),
    datasets: [
      {
        label: "Speed",
        data: helathGraphData?.map(() => Math.random() * 100),
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.1)",
        fill: true,
      },
    ],
  };

  const chartDatagnsspdop = {
    labels: helathGraphData?.map((entry) => entry.gnss_pdop),
    datasets: [
      {
        label: "pdop",
        data: helathGraphData?.map(() => Math.random() * 100),
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.1)",
        fill: true,
      },
    ],
  };

  const chartDataIgnition = {
    labels: helathGraphData?.map((entry) => entry.ignition),
    datasets: [
      {
        label: "ignition",
        data: helathGraphData?.map(() => Math.random() * 100),
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.1)",
        fill: true,
      },
    ],
  };

  const chartDataSignal = {
    labels: helathGraphData?.map((entry) => entry.gsm_signal),
    datasets: [
      {
        label: "gsm signal",
        data: helathGraphData?.map(() => Math.random() * 100),
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.1)",
        fill: true,
      },
    ],
  };

  const chartDatamovement = {
    labels: helathGraphData?.map((entry) => entry.movement),
    datasets: [
      {
        label: "movement",
        data: helathGraphData?.map(() => Math.random() * 100),
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.1)",
        fill: true,
      },
    ],
  };

  const chartDataaltitude = {
    labels: helathGraphData?.map((entry) => entry.altitude),
    datasets: [
      {
        label: "altitude",
        data: helathGraphData?.map(() => Math.random() * 100),
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.1)",
        fill: true,
      },
    ],
  };

  const chartDatahdop = {
    labels: helathGraphData?.map((entry) => entry.gnss_hdop),
    datasets: [
      {
        label: "gnss hdop",
        data: helathGraphData?.map(() => Math.random() * 100),
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.1)",
        fill: true,
      },
    ],
  };

  const chartDatalatitude = {
    labels: helathGraphData?.map((entry) => entry.latitude),
    datasets: [
      {
        label: "latitude",
        data: helathGraphData?.map(() => Math.random() * 100),
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.1)",
        fill: true,
      },
    ],
  };

  const chartDatalongitude = {
    labels: helathGraphData?.map((entry) => entry.longitude),
    datasets: [
      {
        label: "longitude",
        data: helathGraphData?.map(() => Math.random() * 100),
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.1)",
        fill: true,
      },
    ],
  };

  const chartDatatimestamp = {
    labels: helathGraphData?.map((entry) => entry.timestamp),
    datasets: [
      {
        label: "timestamp",
        data: helathGraphData?.map(() => Math.random() * 100),
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.1)",
        fill: true,
      },
    ],
  };

  const grayMapStyle = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#474747",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#474747",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#303030",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#303030",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#303030",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#303030",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#303030",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#303030",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#303030",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#474747",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];

  return (
    <div>
      <div>
        <div className="inner-header py-3">
          <div className="left-block">
            <h5>Health Dashboard</h5>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-9"></div>
            <div className="col-xl-3">
              <div className="vehiclist-box">
                <div className="mb-2">
                  <Select
                    options={vehicleList}
                    value={vehicleList?.find(
                      (option) => option.value === vehicle
                    )}
                    onChange={(selectedOption) =>
                      setVehicle((prevData) => ({
                        ...prevData,
                        vehicleId: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Vehicle"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="container-event-profle event-profle">
            <div
              className="profle-block tab-content healthdashboard-block"
              id="nav-tabContent"
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4">
                    <Speedometer
                      value={helathData.speed}
                      currentValueText={helathData.total_odpmeter}
                      fontFamily="squada-one"
                      // width={200}
                      // height={200}
                    >
                      <Background />
                      <Arc />
                      <Needle />
                      <Progress />
                      <Marks fontSize={12} />
                      <Indicator fontSize={12} />
                    </Speedometer>
                  </div>
                  <div className="col-md-8">
                    {isLoaded ? (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={markerPosition || { lat: 0, lng: 0 }}
                        zoom={11}
                        onClick={onMapClick}
                        // onUnmount={onUnmount}
                        // onLoad={onMapLoad}
                        options={{
                          gestureHandling: "auto",
                          scrollwheel: true,
                          styles: grayMapStyle,
                        }}
                      >
                        {center && (
                          <Marker
                            position={markerPosition}
                            draggable={true}
                            // onDragEnd={onMarkerDragEnd}
                          />
                        )}

                        <Marker
                          position={{
                            lat: parseFloat(helathData.latitude),
                            lng: parseFloat(helathData.longitude),
                          }}
                          zoom={1}
                          options={{ icon: Start }}
                          style={{ cursor: "pointer" }}
                        />

                        {directionsForDevice && helathData.device_imei && (
                          <>
                            <Marker
                              position={{
                                lat: parseFloat(devicedelayData[0]?.latitude),
                                lng: parseFloat(devicedelayData[0]?.longitude),
                              }}
                              zoom={1}
                              options={{ icon: Stop }}
                              style={{ cursor: "pointer" }}
                            />

                            {directionsForDevice.routes && (
                              <DirectionsRenderer
                                directions={directionsForDevice}
                                options={{
                                  suppressMarkers: true,
                                }}
                              />
                            )}
                          </>
                        )}
                      </GoogleMap>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section
            className="section-healthdashboard"
            style={{ height: "auto", paddingBottom: "100px" }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.server_time ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>server_time</h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.server_time}</p>
                        {/* <i
                          class="bi bi-bar-chart-fill"
                          onClick={() => handleClick("server_time")}
                        >
                          <div class="tooltiptext">
                            {isOpen && (
                              <>
                                <div
                                  className={`view-details-modal ${
                                    isHovering ? "hovered" : ""
                                  }`}
                                >
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </i> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>protocol.id</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.server_time}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("protocol.id")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>peer</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.server_time}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("peer")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>ident</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.server_time}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("ident")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>device.type.id</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.server_time}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("device.type.id")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.vehicleName ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>device.name </h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.vehicleName}</p>
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("device.name")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>device.id</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.server_time}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("device.id")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>channel.id</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.server_time}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("channel.id")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.battery_current ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>battery_current</h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.battery_current}</p>
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          onClick={() => handleClick("battery_current")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.battery_voltage ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate || "-"}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>battery_voltage</h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.battery_voltage || ""}</p>
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          onClick={() => handleClickProtocol("battery_voltage")}
                        >
                          <div class="tooltiptext-box">
                            <>
                              <div className={`view-details-modal`}>
                                <div className="body-modal">
                                  <div className="heartbeat-chart">
                                    <Line data={chartDataBattery} />
                                  </div>
                                </div>
                                <div className="footer-modal">
                                  <Button
                                    color="primary"
                                    type="button"
                                    outline
                                    onClick={onCloseProtocol}
                                    className="btn-square"
                                  >
                                    Close
                                  </Button>
                                </div>
                              </div>
                            </>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>can.ambient.air.temperature</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.battery.voltage}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          onClick={() =>
                            handleClick("can.ambient.air.temperature")
                          }
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>can.engine.coolant.temperature</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.battery.voltage}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("can.engine.coolant.temperature")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>can.engine.rpm</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.battery.voltage}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("can.engine.coolant.temperature")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>can.fuel.level</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.battery.voltage}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("can.engine.coolant.temperature")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>can.fuel.type.enum</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.battery.voltage}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("can.fuel.type.enum")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>can.intake.air.temperature</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.battery.voltage}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptex"
                          // onClick={() => handleClick("can.fuel.type.enum")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.speed ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>speed</h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.speed}</p>
                        <i
                          class="bi bi-bar-chart-fill tooltiptex"
                          onClick={() => handleClickSpeed("speed")}
                        >
                          <div class="tooltiptext-box">
                            <>
                              <div className={`view-details-modal`}>
                                <div className="body-modal">
                                  <div className="heartbeat-chart">
                                    <Line data={chartDataSpeed} />
                                  </div>
                                </div>
                                <div className="footer-modal">
                                  <Button
                                    color="primary"
                                    type="button"
                                    outline
                                    onClick={onCloseSpeed}
                                    className="btn-square"
                                  >
                                    Close
                                  </Button>
                                </div>
                              </div>
                            </>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>codec.id</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.codec.id}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptex"
                          // onClick={() => handleClick("codec.id")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.ignition ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>engine.ignition.status</h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.ignition}</p>
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          onClick={() => handleClickIgnition("ignition")}
                        >
                          <div class="tooltiptext-box">
                            <>
                              <div className={`view-details-modal`}>
                                <div className="body-modal">
                                  <div className="heartbeat-chart">
                                    <Line data={chartDataIgnition} />
                                  </div>
                                </div>
                                <div className="footer-modal">
                                  <Button
                                    color="primary"
                                    type="button"
                                    outline
                                    onClick={onCloseIgnition}
                                    className="btn-square"
                                  >
                                    Close
                                  </Button>
                                </div>
                              </div>
                            </>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>event.enum</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.event.enum}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("event.enum")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.gsm_signal ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>gsm_mcc</h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.gsm_signal}</p>
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          onClick={() => handleClickSignal("gsm_signal")}
                        >
                          <div class="tooltiptext-box">
                            <>
                              <div className={`view-details-modal`}>
                                <div className="body-modal">
                                  <div className="heartbeat-chart">
                                    <Line data={chartDataSignal} />
                                  </div>
                                </div>
                                <div className="footer-modal">
                                  <Button
                                    color="primary"
                                    type="button"
                                    outline
                                    onClick={onCloseSignal}
                                    className="btn-square"
                                  >
                                    Close
                                  </Button>
                                </div>
                              </div>
                            </>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.gsm_signal ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>gsm.mnc</h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.gsm_signal}</p>
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          onClick={() => handleClickSignal("gsm_signal")}
                        >
                          <div class="tooltiptext-box">
                            <>
                              <div className={`view-details-modal`}>
                                <div className="body-modal">
                                  <div className="heartbeat-chart">
                                    <Line data={chartDataSignal} />
                                  </div>
                                </div>
                                <div className="footer-modal">
                                  <Button
                                    color="primary"
                                    type="button"
                                    outline
                                    onClick={onCloseSignal}
                                    className="btn-square"
                                  >
                                    Close
                                  </Button>
                                </div>
                              </div>
                            </>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>gsm.mnc</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.gsm.mnc}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("gsm.mnc")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>gsm.network.mode.enum</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.gsm.mnc}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltips"
                          // onClick={() => handleClick("gsm.mnc")}
                        >
                          <div class="tooltiptext">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.active_gsm_operator ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>active_gsm_operator</h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.active_gsm_operator}</p>
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          onClick={() => handleClick("active_gsm_operator")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.gsm_signal ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>gsm.signal.level</h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.gsm_signal}</p>
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          onClick={() => handleClickSignal("gsm_signal")}
                        >
                          <div class="tooltiptext-box">
                            <>
                              <div className={`view-details-modal`}>
                                <div className="body-modal">
                                  <div className="heartbeat-chart">
                                    <Line data={chartDataSignal} />
                                  </div>
                                </div>
                                <div className="footer-modal">
                                  <Button
                                    color="primary"
                                    type="button"
                                    outline
                                    onClick={onCloseSignal}
                                    className="btn-square"
                                  >
                                    Close
                                  </Button>
                                </div>
                              </div>
                            </>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.movement ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>movement.status</h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.movement}</p>
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          onClick={() => handleClickmovement("movement")}
                        >
                          <div class="tooltiptext-box">
                            <>
                              <div className={`view-details-modal`}>
                                <div className="body-modal">
                                  <div className="heartbeat-chart">
                                    <Line data={chartDatamovement} />
                                  </div>
                                </div>
                                <div className="footer-modal">
                                  <Button
                                    color="primary"
                                    type="button"
                                    outline
                                    onClick={onClosemovement}
                                    className="btn-square"
                                  >
                                    Close
                                  </Button>
                                </div>
                              </div>
                            </>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.altitude ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>position.altitude</h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.altitude}</p>
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          onClick={() => handleClickaltitude("altitude")}
                        >
                          <div class="tooltiptext-box">
                            <>
                              <div className={`view-details-modal`}>
                                <div className="body-modal">
                                  <div className="heartbeat-chart">
                                    <Line data={chartDataaltitude} />
                                  </div>
                                </div>
                                <div className="footer-modal">
                                  <Button
                                    color="primary"
                                    type="button"
                                    outline
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    className="btn-square"
                                  >
                                    Close
                                  </Button>
                                </div>
                              </div>
                            </>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>position.direction</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.position.direction}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("position.direction")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.gnss_hdop ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>position.hdop</h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.gnss_hdop}</p>
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          onClick={() => handleClickhdop("gnss_hdop")}
                        >
                          <div class="tooltiptext-box">
                            <>
                              <div className={`view-details-modal`}>
                                <div className="body-modal">
                                  <div className="heartbeat-chart">
                                    <Line data={chartDatahdop} />
                                  </div>
                                </div>
                                <div className="footer-modal">
                                  <Button
                                    color="primary"
                                    type="button"
                                    outline
                                    onClick={onClosehdop}
                                    className="btn-square"
                                  >
                                    Close
                                  </Button>
                                </div>
                              </div>
                            </>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.latitude ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>position.latitude</h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.latitude}</p>
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          onClick={() => handleClicklatitude("latitude")}
                        >
                          <div class="tooltiptext-box">
                            <>
                              <div className={`view-details-modal`}>
                                <div className="body-modal">
                                  <div className="heartbeat-chart">
                                    <Line data={chartDatalatitude} />
                                  </div>
                                </div>
                                <div className="footer-modal">
                                  <Button
                                    color="primary"
                                    type="button"
                                    outline
                                    onClick={onCloselatitude}
                                    className="btn-square"
                                  >
                                    Close
                                  </Button>
                                </div>
                              </div>
                            </>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.longitude ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>position.longitude</h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.longitude}</p>
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          onClick={() => handleClicklongitude("longitude")}
                        >
                          <div class="tooltiptext-box">
                            <>
                              <div className={`view-details-modal`}>
                                <div className="body-modal">
                                  <div className="heartbeat-chart">
                                    <Line data={chartDatalongitude} />
                                  </div>
                                </div>
                                <div className="footer-modal">
                                  <Button
                                    color="primary"
                                    type="button"
                                    outline
                                    onClick={onCloselongitude}
                                    className="btn-square"
                                  >
                                    Close
                                  </Button>
                                </div>
                              </div>
                            </>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.gnss_pdop ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>position.pdop</h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.gnss_pdop}</p>
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          onClick={() => handleClicklongitude("gnss_pdop")}
                        >
                          <div class="tooltiptext-box">
                            <>
                              <div className={`view-details-modal`}>
                                <div className="body-modal">
                                  <div className="heartbeat-chart">
                                    <Line data={chartDatagnsspdop} />
                                  </div>
                                </div>
                                <div className="footer-modal">
                                  <Button
                                    color="primary"
                                    type="button"
                                    outline
                                    // onClick={onCloselongitude}
                                    className="btn-square"
                                  >
                                    Close
                                  </Button>
                                </div>
                              </div>
                            </>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>position.satellites</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.gnss_pdop}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("gnss_pdop")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.speed ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>position.speed</h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.speed}</p>
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          onClick={() => handleClickSpeed("speed")}
                        >
                          <div class="tooltiptext-box">
                            <>
                              <div className={`view-details-modal`}>
                                <div className="body-modal">
                                  <div className="heartbeat-chart">
                                    <Line data={chartDataSpeed} />
                                  </div>
                                </div>
                                <div className="footer-modal">
                                  <Button
                                    color="primary"
                                    type="button"
                                    outline
                                    onClick={onCloseProtocol}
                                    className="btn-square"
                                  >
                                    Close
                                  </Button>
                                </div>
                              </div>
                            </>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>position.valid</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.gnss_pdop}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("gnss_pdop")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>sleep.mode.enum</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.gnss_pdop}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("gnss_pdop")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div
                    className={`card health-dashboard-card ${
                      helathData.timestamp ? `battery-card` : ``
                    }`}
                  >
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>timestamp</h6>
                      </div>
                      <div className="flout">
                        <p>{helathData.timestamp}</p>
                        {/* <i
                          class="bi bi-bar-chart-fill"
                          onClick={() => handleClicktimestamp("timestamp")}
                        >
                          <div class="tooltiptext">
                            {isOpetimestamp && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartDatatimestamp} />
                                    </div>
                                  </div>
                                  <div className="footer-modal">
                                    <Button
                                      color="primary"
                                      type="button"
                                      outline
                                      onClick={onClosetimestamp}
                                      className="btn-square"
                                    >
                                      Close
                                    </Button>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </i> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p>{formattedServerDate}</p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>vehicle.mileage</h6>
                      </div>
                      <div className="flout">
                        {/* <p>{helathData.vehicle.mileage}</p> */}
                        <i
                          class="bi bi-bar-chart-fill tooltiptext"
                          // onClick={() => handleClick("vehicle.mileage")}
                        >
                          <div class="tooltiptext-box">
                            {/* {isOpen && (
                              <>
                                <div className={`view-details-modal`}>
                                  <div className="body-modal">
                                    <div className="heartbeat-chart">
                                      <Line data={chartData} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )} */}
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* <Modal isOpen={isOpen} style={{ marginTop: "250px" }}>
          <ModalHeader>{`View Details`}</ModalHeader>

          <ModalBody>
            <div className="chart-container">
              <div className="heartbeat-chart">
                <Line data={chartData} />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              outline
              onClick={onClose}
              className={`btn-square`}
            >{`Close`}</Button>
          </ModalFooter>
        </Modal> */}

        {/* {isOpen && (
          <>
            <div className={`view-details-modal`}>
              <div className="body-modal">
                <div className="heartbeat-chart">
                  <Line data={chartData} />
                </div>
              </div>
              <div className="footer-modal">
                <Button
                  color="primary"
                  type="button"
                  outline
                  onClick={onClose}
                  className="btn-square"
                >
                  Close
                </Button>
              </div>
            </div>
          </>
        )} */}
      </div>
    </div>
  );
};

export default HealthDashboard;
