import axiosInstance from "./AxiosInstance";

export function createFixedCost(data) {
  return axiosInstance.post(`/fixed-cost-addupdate`, data);
}

export function getFixedCostList(data) {
  return axiosInstance.post(`/fixed-cost-list`, data);
}

export function deleteFixedCost(data) {
  return axiosInstance.post(`/fixed-cost-delete`, data);
}

export function createVariableCost(data) {
  return axiosInstance.post(`/variable-cost-addupdate`, data);
}

export function getVariableCostList(data) {
  return axiosInstance.post(`/variable-cost-list`, data);
}

export function deleteVariableCost(data) {
  return axiosInstance.post(`/variable-cost-delete`, data);
}

export function getVariableAllCostList(data) {
  return axiosInstance.post(`/variable-cost-all-list`, data);
}

export function getFixedAllCostList(data) {
  return axiosInstance.post(`/fixed-cost-all-list`, data);
}

export function createEntities(data) {
  return axiosInstance.post(`/entities-addupdate`, data);
}

export function getEntitiesList(data) {
  return axiosInstance.post(`/entities-list`, data);
}

export function deleteEntities(data) {
  return axiosInstance.post(`/entities-delete`, data);
}

export function getEntitiesAllList(data) {
  return axiosInstance.post(`/entities-all-list`, data);
}

export function createCost(data) {
  return axiosInstance.post(`/cost-addupdate`, data);
}

export function getCostList(data) {
  return axiosInstance.post(`/cost-list`, data);
}

export function deleteCost(data) {
  return axiosInstance.post(`/cost-delete`, data);
}

export function createDepartment(data) {
  return axiosInstance.post(`/department-addupdate`, data);
}

export function getDepartmentList(data) {
  return axiosInstance.post(`/department-list`, data);
}

export function deletDepartment(data) {
  return axiosInstance.post(`/department-delete`, data);
}

export function getFleetList(data) {
  return axiosInstance.post(`/fleet-list`, data);
}

export function createAccount(data) {
  return axiosInstance.post(`/account-addupdate`, data);
}

export function getAccountList(data) {
  return axiosInstance.post(`/account-all-list`, data);
}

export function deletAccount(data) {
  return axiosInstance.post(`/account-delete`, data);
}

export function createFilter(data) {
  return axiosInstance.post(`/filter-addupdate`, data);
}

export function getFilterList(data) {
  return axiosInstance.post(`/filter-list`, data);
}

export function deletFilter(data) {
  return axiosInstance.post(`/filter-delete`, data);
}

export function getFilterAllList(data) {
  return axiosInstance.post(`filter-all-list`, data);
}

export function createSegment(data) {
  return axiosInstance.post(`/segment-addupdate`, data);
}

export function getSegmentList(data) {
  return axiosInstance.post(`/segment-list`, data);
}

export function deletSegment(data) {
  return axiosInstance.post(`/segment-delete`, data);
}

export function getSegmentAllList(data) {
  return axiosInstance.post(`/segment-all-list`, data);
}

export function getDepartmentAllList(data) {
  return axiosInstance.post(`/department-all-list`, data);
}

export function createAccountProfile(data) {
  return axiosInstance.post(`/company/update-profile`, data);
}

export function createAccountGeneral(data) {
  return axiosInstance.post(`/company/update-general-detail`, data);
}

export function createPermittedDriving(data) {
  return axiosInstance.post(`/permitted-driving-period-addupdate`, data);
}

export function getPermittedDrivingList(data) {
  return axiosInstance.post(`/permitted-driving-period-all-list`, data);
}

export function createAccountDrivingCondition(data) {
  return axiosInstance.post(`/company-driving-conditions-addupdate`, data);
}

export function createAccountCanbus(data) {
  return axiosInstance.post(`/company-canbus-addupdate`, data);
}

export function createAccountCalculateTripCost(data) {
  return axiosInstance.post(`/company-update-calculate-trip-costs`, data);
}

export function createAccountAutomationRule(data) {
  return axiosInstance.post(`/company-automation-rule-addupdate`, data);
}

export function createAccountDirection(data) {
  return axiosInstance.post(`/company-direction-addupdate`, data);
}

export function getAccountDirectionList(data) {
  return axiosInstance.post(`/company-direction-list`, data);
}

export function createAccountCRM(data) {
  return axiosInstance.post(`/company-crm-addupdate`, data);
}

export function createAccountAutomaticCreation(data) {
  return axiosInstance.post(
    `/company-automatic-creation-drivers-addupdate`,
    data
  );
}


export function createAccountDriverApp(data) {
  return axiosInstance.post(`/driver-app-setting-addupdate`, data);
}

export function getAccountLicensesList(data) {
  return axiosInstance.post(`/company-licenses-list`, data);
}

export function createAccountLicenses(data) {
  return axiosInstance.post(`/company-licenses-addupdate`, data);
}

export function destroyAccountLicenses(data) {
  return axiosInstance.post(`/company-licenses-delete`, data);
}

export function createAccountRoute(data) {
  return axiosInstance.post(`/company-route-addupdate`, data);
}


export function getAccountDetails(data) {
  return axiosInstance.post(`/company-account-details`, data);
}

export function createRecurringCost(data) {
  return axiosInstance.post(`/recurring-cost-addupdate`, data);
}

export function getCostRecurringList(data) {
  return axiosInstance.post(`/recurring-cost-list`, data);
}

export function deleteRecurringCost(data) {
  return axiosInstance.post(`/recurring-cost-delete`, data);
}