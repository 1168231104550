import React, { useEffect, useState } from "react";
import {
  actionAddNotification,
  getAllgeoFenseList,
  getAllVehicleTaskList,
  getNotificationList,
} from "../../services/AlarmNotificationService";
import { getVehicleList } from "../../services/VehicleService";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import Select from "react-select";

const tableLengthList = configDb.data.dataTableLength;

const GeneralNotification = ({
  setNotificationStatus,
  NotificationDataAdd,
}) => {
  const Type = [
    { value: 1, label: "OverSpeed", name: "over_speed" },
    { value: 2, label: "LowerSpeed", name: "low_speed" },
    { value: 3, label: "Maintainance Task", name: "maintainance_task" },
    { value: 4, label: "Geo Fense IN", name: "in" },
    { value: 5, label: "Temperature", name: "temperature" },
    { value: 6, label: "Geo Fense OUT", name: "out" },
    {
      value: 7,
      name: "ad_blue_low_level",
      label: "ADBlue Level",
    },
    {
      value: 8,

      name: "daily_activity_start",
      label: "Daily Activity Start",
    },
    {
      value: 9,

      name: "engine_coolant_temperature_level",
      label: "Engine Coolant Temperature",
    },
    {
      value: 10,

      name: "fuel_consumed_level",
      label: "Fuel Consumed",
    },
    {
      value: 11,

      name: "fuel_rate_level",
      label: "Fuel Rate",
    },
   
    {
      value: 12,

      name: "tachno_vehicle_over_speed",
      label: "Tachno vehicle Speed",
    },

  
    // { value: 7, label: "Fuel" },
  ];
  const [notificationData, setNotificationData] = useState({
    uuid: "",
    type_name: "",
    temperature: "",
    lower_speed_limit: "",
    over_speed_limit: "",
    maintainance_task_id: "",
    email: "",
    geo_fense_id: "",
    vehicle_id: "",
    ad_blue_low_level: "",
    battery_voltage_level: "",
    engine_coolant_temperature_level: "",
    fuel_consumed_level: "",
    fuel_rate_level: "",
    tachno_vehicle_over_speed: "",
  });
  const [vehicleList, setVehicleList] = useState([]);
  const [vehicleTaskList, setVehicleTaskList] = useState([]);
  const [geofenseList, setgeoFenseList] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicle, setVehicle] = useState("");
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  let errorsObj = {
    email: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const handleChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "All")) {
      setNotificationData({ ...notificationData, vehicle_id: ["All"] });
    } else {
      const selectedValues = selectedOptions.map((option) => option.value);
      setNotificationData({ ...notificationData, vehicle_id: selectedValues });
    }
  };
  // const handleChange = (selectedOptions) => {
  //   const selectedValues = selectedOptions
  //     ? selectedOptions.map((option) => option.value)
  //     : [];
  //   setNotificationData((prevData) => ({
  //     ...prevData,
  //     vehicle_id: selectedValues,
  //   }));
  // };

  useEffect(() => {
    if (NotificationDataAdd) {
      console.log("NotificationDataAdd", NotificationDataAdd);
      let typeName = "";

      switch (NotificationDataAdd.type_name) {
        case "over_speed":
          typeName = 1;
          break;
        case "low_speed":
          typeName = 2;
          break;
        case "maintainance_task":
          typeName = 3;
          break;
        case "in":
          typeName = 4;
          break;
        case "temperature":
          typeName = 5;
          break;
        case "out":
          typeName = 6;
          break;

        case "ad_blue_low_level":
          typeName = 7;
          break;
        case "daily_activity_start":
          typeName = 8;
          break;
        case "engine_coolant_temperature_level":
          typeName = 9;
          break;
        case "fuel_consumed_level":
          typeName = 10;
          break;
        case "fuel_rate_level":
          typeName = 11;
          break;
        case "analog_level":
          typeName = 12;
          break;
        case "tachno_vehicle_over_speed":
          typeName = 13;
          break;
        case "digital_input_level":
          typeName = 14;
          break;
        default:
          typeName = "Unknown Type";
          break;
      }

      setNotificationData({
        uuid: NotificationDataAdd?.uuid,
        type_name: NotificationDataAdd?.type_name,
        temperature: NotificationDataAdd?.temperature,
        lower_speed_limit: NotificationDataAdd?.lower_speed_limit,
        over_speed_limit: NotificationDataAdd?.over_speed_limit,
        maintainance_task_id: NotificationDataAdd?.maintainance_task_id,
        ad_blue_low_level: NotificationDataAdd?.ad_blue_low_level,
        daily_activity_start: NotificationDataAdd?.daily_activity_start,
        engine_coolant_temperature_level:
          NotificationDataAdd?.engine_coolant_temperature_level,
        fuel_consumed_level: NotificationDataAdd?.fuel_consumed_level,
        fuel_rate_level: NotificationDataAdd?.fuel_rate_level,
        analog_level: NotificationDataAdd?.analog_level,
        tachno_vehicle_over_speed:
          NotificationDataAdd?.tachno_vehicle_over_speed,
        digital_input_level: NotificationDataAdd?.digital_input_level,

        email: NotificationDataAdd?.email,
        geo_fense_id: NotificationDataAdd?.geo_fense_id,
        vehicle_id: NotificationDataAdd.vehicle_id,
      });
    }
  }, []);

  const handleAddNotificationForm = (e) => {
    e.preventDefault();

    let data = {
      type_name: notificationData.type_name,
      temperature: notificationData.temperature,
      lower_speed_limit: notificationData.lower_speed_limit,
      over_speed_limit: notificationData.over_speed_limit,
      maintainance_task_id: notificationData.maintainance_task_id,
      email: notificationData.email,
      geo_fense_id: notificationData.geo_fense_id,
      vehicle_id: notificationData.vehicle_id,
      ad_blue_low_level: notificationData.ad_blue_low_level,
      battery_voltage_level: notificationData.battery_voltage_level,
      engine_coolant_temperature_level:
        notificationData.engine_coolant_temperature_level,
      fuel_consumed_level: notificationData.fuel_consumed_level,
      fuel_rate_level: notificationData.fuel_rate_level,

      tachno_vehicle_over_speed: notificationData.tachno_vehicle_over_speed,
    };

    let updatedata = {
      type_name: notificationData.type_name,
      temperature: notificationData.temperature,
      lower_speed_limit: notificationData.lower_speed_limit,
      over_speed_limit: notificationData.over_speed_limit,
      maintainance_task_id: notificationData.maintainance_task_id,
      email: notificationData.email,
      geo_fense_id: notificationData.geo_fense_id,
      vehicle_id: notificationData.vehicle_id,
      ad_blue_low_level: notificationData.ad_blue_low_level,
      battery_voltage_level: notificationData.battery_voltage_level,
      engine_coolant_temperature_level:
        notificationData.engine_coolant_temperature_level,
      fuel_consumed_level: notificationData.fuel_consumed_level,
      fuel_rate_level: notificationData.fuel_rate_level,

      tachno_vehicle_over_speed: notificationData.tachno_vehicle_over_speed,
      uuid: notificationData.uuid,
    };

    if (NotificationDataAdd?.uuid) {
      modifySubscription(actionAddNotification, updatedata);
    } else {
      modifySubscription(actionAddNotification, data);
    }
  };

  const modifySubscription = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setNotificationStatus(false);
        getNotificationList(dataParams)
          .then((response) => {})
          .catch((err) => {});
        setNotificationData({
          type_name: "",
          temperature: "",
          lower_speed_limit: "",
          over_speed_limit: "",
          maintainance_task_id: "",
          email: "",
          geo_fense_id: "",
          vehicle_id: "",
          temperature: "",
        });
        window.location.reload();
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(() => {
    let data = params;
    getVehicleList(data)
      .then((response) => {
        response = response.data;
        let list = [];

        list.push({ value: "All", label: "All" });

        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].vehicleName,
          });
        }

        setVehicleList(list);
      })
      .catch((err) => {
        console.error("Error fetching vehicle list:", err);
      });
  }, [params]);

  useEffect(
    function () {
      let data = params;
      getAllVehicleTaskList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].task_name,
            });
          }

          setVehicleTaskList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      getAllgeoFenseList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].radius_zonename,
            });
          }

          setgeoFenseList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getNotificationList(data)
        .then((response) => {
          setNotificationList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form
              method={`post`}
              onSubmit={handleAddNotificationForm}
              className="row"
            >
              <FormGroup className="col-lg-4">
                <Label className="col-form-label">{"Alert Type"}</Label>
                <div className="mb-3">
                  <Select
                    options={Type}
                    value={Type.find(
                      (option) => option.name === notificationData.type_name
                    )}
                    onChange={(selectedOption) =>
                      setNotificationData((prevData) => ({
                        ...prevData,
                        type_name: selectedOption.name,
                      }))
                    }
                    placeholder={"Select Alert Type"}
                  />
                  {/* {errors.type_name && (
                    <span className="input-error">{errors.type_name}</span>
                  )} */}
                </div>
              </FormGroup>
              {(notificationData.type_name === "over_speed" ||
                notificationData.type_name === 1) && (
                <FormGroup className="col-lg-6">
                  <Label className="col-form-label">
                    {"OverSpeed Limit"}
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={notificationData.over_speed_limit}
                      onChange={(e) => {
                        setNotificationData({
                          ...notificationData,
                          over_speed_limit: e.target.value,
                        });
                      }}
                      name="over_speed_limit"
                      maxLength={50}
                      placeholder="OverSpeed Limit"
                    />
                    {/* {errors.over_speed_limit && (
                      <span className="input-error">
                        {errors.over_speed_limit}
                      </span>
                    )} */}
                  </div>
                </FormGroup>
              )}

              {(notificationData.type_name === "low_speed" ||
                notificationData.type_name === 2) && (
                <FormGroup>
                  <Label className="col-form-label">
                    {"Low Speed Limit"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={notificationData.lower_speed_limit}
                      onChange={(e) => {
                        setNotificationData({
                          ...notificationData,
                          lower_speed_limit: e.target.value,
                        });
                      }}
                      name={`lower_speed_limit`}
                      maxLength={50}
                      placeholder="Low Speed Limit"
                    />
                    {/* {errors.lower_speed_limit && (
                      <span className="input-error">
                        {errors.lower_speed_limit}
                      </span>
                    )} */}
                  </div>
                </FormGroup>
              )}

              {(notificationData.type_name === "maintainance_task" ||
                notificationData.type_name === 3) && (
                <FormGroup>
                  <Label className="col-form-label">
                    {"Maintainance Task"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="mb-3">
                    <Select
                      options={vehicleTaskList}
                      value={vehicleTaskList.find(
                        (option) =>
                          option.value === notificationData.maintainance_task_id
                      )}
                      onChange={(selectedOption) =>
                        setNotificationData((prevData) => ({
                          ...prevData,
                          maintainance_task_id: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Maintainance Task"}
                    />
                    {/* {errors.type && (
      <span className="input-error">{errors.type}</span>
    )} */}
                  </div>
                </FormGroup>
              )}

              {(notificationData.type_name === "in" ||
                notificationData.type_name === 4) && (
                <>
                  <FormGroup>
                    <Label className="col-form-label">
                      {"Zone"}
                      <span className={`text-danger ml-1`}>*</span>
                    </Label>
                    <div className="mb-3">
                      <Select
                        options={geofenseList}
                        value={geofenseList.find(
                          (option) =>
                            option.value === notificationData.geo_fense_id
                        )}
                        onChange={(selectedOption) =>
                          setNotificationData((prevData) => ({
                            ...prevData,
                            geo_fense_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Zone"}
                      />
                      {/* {errors.type && (
       <span className="input-error">{errors.type}</span>
     )} */}
                    </div>
                  </FormGroup>
                </>
              )}

              {(notificationData.type_name === "out" ||
                notificationData.type_name === 6) && (
                <>
                  <FormGroup>
                    <Label className="col-form-label">
                      {"Zone"}
                      <span className={`text-danger ml-1`}>*</span>
                    </Label>
                    <div className="mb-3">
                      <Select
                        options={geofenseList}
                        value={geofenseList.find(
                          (option) =>
                            option.value === notificationData.geo_fense_id
                        )}
                        onChange={(selectedOption) =>
                          setNotificationData((prevData) => ({
                            ...prevData,
                            geo_fense_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Zone"}
                      />
                    </div>
                  </FormGroup>
                </>
              )}

              {(notificationData.type_name === "temperature" ||
                notificationData.type_name === 5) && (
                <FormGroup>
                  <Label className="col-form-label">
                    {"Temperature"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={notificationData.temperature}
                      onChange={(e) => {
                        setNotificationData({
                          ...notificationData,
                          temperature: e.target.value,
                        });
                      }}
                      name={`temperature`}
                      maxLength={50}
                      placeholder="Temperature"
                    />
                    {/* {errors.temperature && (
                      <span className="input-error">{errors.temperature}</span>
                    )} */}
                  </div>
                </FormGroup>
              )}

              {(notificationData.type_name === "ad_blue_low_level" ||
                notificationData.type_name === 7) && (
                <FormGroup className="col-lg-6">
                  <Label className="col-form-label">{"ADBlue Level(%)"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={notificationData.ad_blue_low_level}
                      onChange={(e) => {
                        setNotificationData({
                          ...notificationData,
                          ad_blue_low_level: e.target.value,
                        });
                      }}
                      name="ad_blue_low_level"
                      maxLength={50}
                      placeholder="ADBlue Level(%)"
                    />
                    {/* {errors.ad_blue_low_level && (
                      <span className="input-error">
                        {errors.ad_blue_low_level}
                      </span>
                    )} */}
                  </div>
                </FormGroup>
              )}

              {(notificationData.type_name === "daily_activity_start" ||
                notificationData.type_name === 8) && (
                <FormGroup className="col-lg-6">
                  <Label className="col-form-label">
                    {"Daily Activity Start"}
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={notificationData.daily_activity_start}
                      onChange={(e) => {
                        setNotificationData({
                          ...notificationData,
                          daily_activity_start: e.target.value,
                        });
                      }}
                      name="daily_activity_start"
                      maxLength={50}
                      placeholder="Daily Activity Start"
                    />
                    {/* {errors.daily_activity_start && (
                      <span className="input-error">
                        {errors.daily_activity_start}
                      </span>
                    )} */}
                  </div>
                </FormGroup>
              )}

              {(notificationData.type_name ===
                "engine_coolant_temperature_level" ||
                notificationData.type_name === 9) && (
                <FormGroup className="col-lg-6">
                  <Label className="col-form-label">
                    {"Engine Coolant Temperature"}
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={notificationData.engine_coolant_temperature_level}
                      onChange={(e) => {
                        setNotificationData({
                          ...notificationData,
                          engine_coolant_temperature_level: e.target.value,
                        });
                      }}
                      name="engine_coolant_temperature_level"
                      maxLength={50}
                      placeholder="Engine Coolant Temperature"
                    />
                    {/* {errors.engine_coolant_temperature_level && (
                      <span className="input-error">
                        {errors.engine_coolant_temperature_level}
                      </span>
                    )} */}
                  </div>
                </FormGroup>
              )}

              {(notificationData.type_name === "fuel_consumed_level" ||
                notificationData.type_name === 8) && (
                <FormGroup className="col-lg-6">
                  <Label className="col-form-label">{"Fuel Consumed"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={notificationData.fuel_consumed_level}
                      onChange={(e) => {
                        setNotificationData({
                          ...notificationData,
                          fuel_consumed_level: e.target.value,
                        });
                      }}
                      name="fuel_consumed_level"
                      maxLength={50}
                      placeholder="Fuel Consumed"
                    />
                    {/* {errors.fuel_consumed_level && (
                      <span className="input-error">
                        {errors.fuel_consumed_level}
                      </span>
                    )} */}
                  </div>
                </FormGroup>
              )}

              {(notificationData.type_name === "fuel_rate_level" ||
                notificationData.type_name === 9) && (
                <FormGroup className="col-lg-6">
                  <Label className="col-form-label">{"Fuel Rate"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={notificationData.fuel_rate_level}
                      onChange={(e) => {
                        setNotificationData({
                          ...notificationData,
                          fuel_rate_level: e.target.value,
                        });
                      }}
                      name="fuel_rate_level"
                      maxLength={50}
                      placeholder="Fuel Rate"
                    />
                    {/* {errors.fuel_rate_level && (
                      <span className="input-error">
                        {errors.fuel_rate_level}
                      </span>
                    )} */}
                  </div>
                </FormGroup>
              )}

              {(notificationData.type_name === "analog_level" ||
                notificationData.type_name === 9) && (
                <FormGroup className="col-lg-6">
                  <Label className="col-form-label">{"Analog Level"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={notificationData.analog_level}
                      onChange={(e) => {
                        setNotificationData({
                          ...notificationData,
                          analog_level: e.target.value,
                        });
                      }}
                      name="analog_level"
                      maxLength={50}
                      placeholder="Analog Level"
                    />
                    {/* {errors.analog_level && (
                      <span className="input-error">
                        {errors.analog_level}
                      </span>
                    )} */}
                  </div>
                </FormGroup>
              )}

              {(notificationData.type_name === "tachno_vehicle_over_speed" ||
                notificationData.type_name === 9) && (
                <FormGroup className="col-lg-6">
                  <Label className="col-form-label">
                    {"Tachno vehicle Speed"}
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={notificationData.tachno_vehicle_over_speed}
                      onChange={(e) => {
                        setNotificationData({
                          ...notificationData,
                          tachno_vehicle_over_speed: e.target.value,
                        });
                      }}
                      name="tachno_vehicle_over_speed"
                      maxLength={50}
                      placeholder="Tachno vehicle Speed"
                    />
                    {/* {errors.tachno_vehicle_over_speed && (
                      <span className="input-error">
                        {errors.tachno_vehicle_over_speed}
                      </span>
                    )} */}
                  </div>
                </FormGroup>
              )}

              {(notificationData.type_name === "digital_input_level" ||
                notificationData.type_name === 9) && (
                <FormGroup className="col-lg-6">
                  <Label className="col-form-label">{"Digital Input"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={notificationData.digital_input_level}
                      onChange={(e) => {
                        setNotificationData({
                          ...notificationData,
                          digital_input_level: e.target.value,
                        });
                      }}
                      name="digital_input_level"
                      maxLength={50}
                      placeholder="Digital Input"
                    />
                    {/* {errors.digital_input_level && (
                      <span className="input-error">
                        {errors.digital_input_level}
                      </span>
                    )} */}
                  </div>
                </FormGroup>
              )}

              {(notificationData.type_name === "over_speed" ||
                notificationData.type_name === "low_speed" ||
                notificationData.type_name === "maintainance_task" ||
                notificationData.type_name === "in" ||
                notificationData.type_name === "temperature" ||
                notificationData.type_name === "out" ||
                notificationData.type_name === "ad_blue_low_level" ||
                notificationData.type_name === "daily_activity_start" ||
                notificationData.type_name ===
                  "engine_coolant_temperature_level" ||
                notificationData.type_name === "fuel_consumed_level" ||
                notificationData.type_name === "fuel_rate_level" ||
                notificationData.type_name === "analog_level" ||
                notificationData.type_name === "tachno_vehicle_over_speed" ||
                notificationData.type_name === "digital_input_level" ||
                notificationData.type_name === 1 ||
                notificationData.type_name === 2 ||
                notificationData.type_name === 3 ||
                notificationData.type_name === 4 ||
                notificationData.type_name === 5 ||
                notificationData.type_name === 6 ||
                notificationData.type_name === 7 ||
                notificationData.type_name === 8 ||
                notificationData.type_name === 9 ||
                notificationData.type_name === 10 ||
                notificationData.type_name === 11 ||
                notificationData.type_name === 12 ||
                notificationData.type_name === 13 ||
                notificationData.type_name === 14) && (
                <>
                  <FormGroup className="col-lg-6">
                    <Label className="col-form-label">
                      {"Email"}
                      <span className={`text-danger ml-1`}>*</span>
                    </Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={notificationData.email}
                        onChange={(e) => {
                          setNotificationData({
                            ...notificationData,
                            email: e.target.value,
                          });
                        }}
                        name={`email`}
                        maxLength={50}
                        placeholder="Email"
                      />
                      {/* {errors.email && (
                        <span className="input-error">{errors.email}</span>
                      )} */}
                    </div>
                  </FormGroup>

                  <FormGroup className="col-lg-6">
                    <Label className="col-form-label">{"Vehicle"}</Label>
                    <div className="input-div">
                      <Select
                        options={
                          notificationData.vehicle_id.includes("All")
                            ? vehicleList.filter(
                                (option) => option.value === "All"
                              )
                            : vehicleList
                        }
                        isMulti
                        value={
                          notificationData.vehicle_id.includes("All")
                            ? vehicleList.filter(
                                (option) => option.value === "All"
                              )
                            : vehicleList.filter((option) =>
                                notificationData.vehicle_id.includes(
                                  option.value
                                )
                              )
                        }
                        onChange={handleChange}
                        placeholder={"Select Vehicle"}
                        classNamePrefix="select"
                        closeMenuOnSelect={false}
                      />
                    </div>
                  </FormGroup>
                </>
              )}
              <div className="row">
                <div className="col-lg-6">
                  <Button
                    color="success btn-info"
                    type={`submit`}
                    className={`btn-square`}
                  >{`Submit`}</Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralNotification;
