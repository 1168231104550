import React from "react";
import OrderList from "./OrderList";

const TrackingOrderList = () => {
  return (
    <div>
      <OrderList
      // setActiveTab={setActiveTab}
      // customerDetails={customerDetails}
      // setAddCustomerStatus={setAddCustomerStatus}
      />
    </div>
  );
};

export default TrackingOrderList;
